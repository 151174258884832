import Vue from 'vue'
import Notes from './notes.vue'
import OtherVersionNotes from './other_version_notes.vue'

$(document).ready(() => {
  $(".mix-notes").each( (_, div) => {
    new Vue({
      el: div,
      render: h => h(Notes, { props: $(div).data() } )
    })
  });

  $(".mix-notes-other-versions").each( (_, div) => {
    new Vue({
      el: div,
      render: h => h(OtherVersionNotes, { props: $(div).data() } )
    })
  })
})

