<template>
  <div>
     <a href='#' @click.prevent="show = !show" class="ml-2">{{ caret }} {{ notes.length }} notes from other versions</a>
     <Notes v-if="show" hide-complete-option="true" hide-create="true" show-version="true" :notes="notes" :owns-mix="ownsMix" :mid-id="mixId"/>
  </div>
</template>

<script>
import Notes from "./notes.vue";
import Vue from 'vue';
import { formatISO, parseJSON, compareAsc} from "date-fns";

export default {
  props: ["notes", "mix-id", "owns-mix", "hide-create", "show-version"],
  components: { Notes },
  data: function() {
    return { show: false }
  }, 
  computed: {
    caret() { 
      return this.show ? '▼' : '▶'
    }
  }
}
</script>